<template>
  <Layout>
    <div class="container mt-5 mb-5">
      <div class="row mt-5 mb-5 text-center">
        <h3>购买课程</h3>
      </div>
      <div class="row">
        <div class="course-cart py-120">
          <div class="container">
            <div class="course-cart-wrapper">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th>课程名称</th>
                    <th>课程有效期</th>
                    <th>价格</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <span>{{ course.name }}</span>
                    </td>

                    <td>
                      <div class="cart-price">
                        <span>{{ course.day }}天</span>
                      </div>
                    </td>

                    <td>
                      <div class="cart-price">
                        <span>￥{{ course.price }}.00</span>
                      </div>
                    </td>
                    <td>
                      <a href="#" class="cart-remove"><i class="far fa-times"></i></a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="cart-footer">
                <div class="row">
                  <div class="col-md-6 col-lg-4">

                  </div>
                  <div class="col-md-6 col-lg-8">
                    <div class="cart-summary">
                      <div class="text-end mt-40">
                        <div class="theme-btn" @click="addOrder" v-if="isAdd">确认订单</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main'

export default {
  name: 'index',
  components: {Layout},
  data() {
    return {
      course: [],
      isAdd: true
    }
  },
  methods: {
    init() {
      const vm = this
      this.$axios.get('/Course/GetCourse', {params: {id: this.$route.query.id}}).then((res) => {
        vm.course = res.data.data
      })
    },
    addOrder() {
      this.isAdd = false

      this.$axios.post('/Order/AddOrder', {CourseID: this.$route.query.id}).then((res) => {
        this.$message({
          showClose: true,
          message: res.data.msg,
          type: res.data.code === 1001 ? 'success' : 'warning'
        })

        if (res.data.code === 1001) {
          this.$router.push({path: '/order/details', query: {id: res.data.data}})
        } else {
          this.isAdd = true
        }

      })
    }

  },
  created() {
    this.init()
  }
}
</script>

<style scoped>

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
  margin: 0px;
  font-weight: 600;
  font-family: 'Heebo', sans-serif;
  line-height: 1.2;
}

h5 {
  font-size: 18px;
}


p {
  margin: 0px;
}

.table {
  text-align: center;
}

.table > :not(:first-child) {
  border-top: none;
}

.table > :not(caption) > * > * {
  padding: 0.8rem 0.8rem;
}

/* course cart */

.course-cart thead tr {
  background: #00AF92;
  color: #fff;
}

.course-cart thead tr th {
  text-transform: capitalize;
}

.course-cart thead tr th,
.course-cart thead tr td {
  white-space: nowrap;
}

.cart-img {
  width: 100px;
}

.cart-img img {
  width: 100%;
  border-radius: 5px;
}

.course-cart tr td {
  color: #383838;
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6;
  border-top: none;
  position: relative;
  padding: 20px;
  font-size: 16px;
}

.cart-qty {
  width: 150px;
}

.cart-qty button {
  color: #383838;
  border: 0;
  border-radius: 5px;
  padding: 4px 13px;
}

.cart-qty input {
  width: 50px;
  padding: 4px 13px;
  border-radius: 5px;
  border: none;
  background: #EFEFEF;
  text-align: center;
}

.cart-remove:hover {
  color: #da1d25;
}

.cart-footer {
  margin-top: 40px;
}

.cart-coupon {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 30px;
}

.cart-coupon .form-control {
  box-shadow: none;
  padding: 10px 14px;
  border-radius: 5px;
}

.cart-coupon .form-control:focus {
  border-color: #00AF92;
}

.coupon-btn {
  padding: 8px 14px;
  color: #fff;
  border: none;
  background: #00AF92;
  border-radius: 5px;
  transition: all .5s ease-in-out;
}

.coupon-btn:hover {
  background: #0A0F1F;
}

.cart-summary {
  float: right;
}

.cart-summary li {
  margin-bottom: 10px;
  width: 200px;
}

.cart-summary li span {
  float: right;
}

.cart-summary li strong {
  color: #030207;
}

.cart-total {
  border-top: 1px solid #eee;
}

.cart-total span {
  font-weight: bold;
  color: #da1d25;
}

.cart-summary .theme-btn {
  margin-right: 8px;
}

.theme-btn {
  background: #00AF92;
}

</style>
