<template>
  <div>
    <header class="header">
      <div class="main-navigation">
        <nav class="navbar navbar-expand-lg">
          <div class="container">
            <a class="navbar-brand" href="/">
              <img :src="imgUrl" alt="logo" height="80px">
            </a>
            <div id="main_nav" class="collapse navbar-collapse">

              <router-link
                  v-if="startvm.code == 1001"
                  :to=" { path: '/courses/view',query:{cid:startvm.data.subjection,ccid:startvm.data.id} } "
              >

                <div class="start-vm">
                  <img :src="vmUrl" width="65px"/>
                  <div class="text">
                    <b> {{ startvm.data.name }}</b>
                    <el-tag size="medium">
                      已开机
                    </el-tag>
                  </div>
                </div>
              </router-link>


              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" href="/">首页</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/card"><i class="uil uil-gift m-2"></i>礼品卡</a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="/order/list">订单中心</a>
                </li>
                <!--                <li class="nav-item">-->
                <!--                  <a class="nav-link" href="/"><i class="uil uil-info-circle"></i>关于我们</a>-->
                <!--                </li>-->
              </ul>


              <div class="header-nav-right">
                <div class="header-user dropdown">
                  <i class="uil uil-user icon"></i>
                  <a href="#">{{ userinfo.netname }}</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="/my/index">个人中心</a></li>
                    <li><a class="dropdown-item" href="/logout">退出</a></li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </nav>
      </div>
    </header>

    <slot/>

    <div class="container">
      <div class="copyright">
        <div class="row">
          <div class="col-lg-6 align-self-center">
            <p class="copyright-text">
              © Copyright <span>{{ new Date().getFullYear() }}</span> <a href="#"> ONE ORG </a> All Rights
              Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      imgUrl: require('../../assets/images/logo.svg'),
      vmUrl: require('../../assets/images/vm.svg'),
      userinfo: [],
      startvm: [],
    }
  },
  methods: {
    init() {
      const vm = this
      this.$axios.get('/User/Info').then((res) => {
        vm.userinfo = res.data.data
      })

      this.$axios.get('/VM/GetUserStartEnv').then((res) => {
        vm.startvm = res.data
      })
    }
  },
  created() {
    this.init()
  }
}
</script>

<style scoped>


.header {
  border-bottom: 3px solid rgb(31 163 162);
  padding-top: 25px;
  padding-bottom: 10px;
}


@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1170px;
  }

}

/* ===================
nav menu css
====================== */

.navbar {
  background: #fff;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 999;
}

.nav-item {
  margin: 0 46px 0 0;
}

.header-user {
  margin-right: 15px;
  font-size: 16px;
}

.header-user .icon {
  margin-right: 10px;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.navbar.fixed-top .nav-item .nav-link::before {
  color: #00235A;
}


button.navbar-toggler {
  font-size: 3.5rem;
  padding: 0;
  margin: 0;
}

button.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: inherit;
  height: inherit;
}

.navbar-brand img {

}


.nav-category button {
  background: transparent;
  border: none;
  font-weight: 500;
  color: #202029;
  padding: 10px 0;
  transition: all .5s ease-in-out;
}

.nav-category button:hover {
  color: #00AF92;
}

.nav-category button i {
  color: #00AF92;
  margin-right: 5px;
}

.nav-category .dropdown-menu .dropdown-item {
  font-weight: 500;
  color: #202029;
  transition: all .5s ease-in-out;
}

.nav-category .dropdown-menu .dropdown-item:hover {
  background: transparent;
  color: #00AF92;
  padding-left: 25px;
}


/* dropdown toggler */
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  font-family: 'Font Awesome 5 Pro';
  content: "\f107";
  font-weight: 500;
  border: none;
  font-size: 16px;
}

/* mobile menu */
.mobile-menu-right {
  display: flex;
  align-items: center;
}


@media all and (min-width: 992px) {
  .navbar .nav-item .nav-link {
    padding: 25px 0 25px 0;
    font-size: 16px;
    font-weight: 500;
    color: #202029;
    text-transform: capitalize;
  }

  .navbar .nav-item:last-child .nav-link {
    margin-right: 0;
  }


  .navbar .nav-item .dropdown-menu li {
    border-bottom: 1px solid #eee;
  }

  .navbar .nav-item .dropdown-menu li:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  .navbar .nav-item .dropdown-menu .dropdown-item {
    font-size: 15px;
    font-weight: 500;
    padding: 8px 15px;
    color: #202029;
    position: relative;
    text-transform: capitalize;
    transition: all .5s ease-in-out;
  }

  .navbar .nav-item .dropdown-menu .dropdown-item:hover {
    background: transparent;
    color: #00AF92;
    padding-left: 25px;
  }

  .navbar .nav-item .nav-link {
    position: relative;
  }

  .navbar .nav-item .nav-link.active,
  .navbar .nav-item:hover .nav-link {
    color: #00AF92;
  }

  .navbar .nav-item:hover .dropdown-menu {
    transition: .3s;
    opacity: 1;
    visibility: visible;
    top: 100%;
    transform: rotateX(0deg);
  }

  .navbar .dropdown-menu-end {
    right: 0;
    left: auto;
  }

  .navbar .dropdown-menu.fade-down {
    top: 80%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
  }

  .navbar .dropdown-menu.fade-up {
    top: 140%;
  }

  .header-nav-right {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 30px;
  }

  .header-nav-search a {
    color: #202029;
    font-size: 18px;
    padding: 5px 10px;
  }

  .header-nav-search a:hover {
    color: #00AF92;
  }

  .header-cart a {
    font-size: 18px;
    position: relative;
  }

  .header-cart a:hover {
    color: #00AF92;
  }

  .header-cart a span {
    background: #00AF92;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50px;
    text-align: center;
    top: -5px;
    right: -10px;
  }

  .header-btn {
    background: #00AF92;
    color: #fff;
    padding: 8px 18px;
    border-radius: 5px;
    font-weight: 500;
    transition: all .5s ease-in-out;
  }

  .header-btn:hover {
    background: #202029;
    color: #fff;
  }

  #main_nav {
    justify-content: flex-end;
  }

}

@media all and (max-width: 1199px) {
  .navbar .header-btn {
    display: none;
  }

  .navbar .header-btn {
    display: none;
  }

  .nav-category {
    display: none;
  }
}

@media all and (max-width: 991px) {
  .navbar {
    top: 0;
    right: 0;
    left: 0;
    position: fixed;
  }

  .navbar-brand {
    padding-left: 10px;
  }

  .navbar-brand img {
    width: 190px;
  }

  .navbar-collapse {
    max-height: 290px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 20px;
    background-color: #fff;
  }

  .dropdown-toggle::after {
    float: right;
  }

  .navbar .nav-item .nav-link {
    color: #202029;
    font-weight: 700;
    transition: all .5s ease-in-out;
  }

  .navbar .nav-item .nav-link:hover {
    color: #00AF92 !important;
  }

  .navbar-toggler-icon {
    font-size: 32px;
    color: #202029;
    font-weight: 500;
    padding-right: 10px;
    margin-bottom: 10px;
  }

  .navbar .dropdown-menu {
    border-radius: 0px;
  }

  .navbar.fixed-top .navbar-toggler-icon {
    color: #202029;
  }

  .header-nav-right {
    display: none;
  }

}

.start-vm {
  display: flex;
  margin-right: 46px;
  color: #2b85fb;
}

.start-vm img {
  border: 2px solid #2b85fb;
  border-radius: 100%;
  padding: 7px;
  margin-right: 15px;
}

.start-vm .text {
  display: grid;
  text-align: center;
}

.copyright {
  padding: 20px 0;
  border-top: 1px solid #dee0e4;
}

.copyright .copyright-text {
  color: #00184f;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 17px;
}

.copyright .copyright-text a {
  color: #00AF92;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown > .dropdown-toggle:active {
  pointer-events: none;
}

.dropdown-item {
  font-size: 14px;
}

</style>